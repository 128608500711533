.image-container{
    width: 80%;
    margin: 0 auto;
}
.image-header{
    font-weight: 600;
    font-size: 28px;
    margin: 40px 0px 20px 0px;
}
.image-name{
    color: #005cbb;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 15px;
}
.image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 0px;
    border: 1px solid silver;
    padding: 30px;
    
  }
  .image-gallery a{text-decoration: none;}
  .image-tile {
    border: 1px solid #ccc;
    border-radius: 0px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    min-height: 165px;
    min-width: 202px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-tile:hover {
    border:1px solid green;
  }
  .image-tile img {
    max-width: 100%;
    height: auto;
  }
  .image-tile-link{cursor: pointer;}
  