.App, body {
  background-color: #F1F1F1;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #444;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.backbtn{background-color: transparent;border: 0px;cursor: pointer;font-weight: 600;font-size: 20px;}
.buttonCtrl{float: right;background-color: green;color: white;font-size: 20px;font-weight: 600;padding: 10px 20px;
  border-radius: 5px;border: 0px;cursor: pointer;min-width: 120px;box-shadow: 0px 8px 0px #444;}

.asterik{color: red;font-weight: 600;font-size: 20px;margin-left: 3px;}
.redBorder{border-color: red;border-width: 1px !important;}


