.leftnav{
    margin-top: 50px;max-width: 400px;
}
.leftnav button{
    background-color: transparent;
    border: 0px;
    color: #005cbb;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 15px;
    cursor: pointer;
    padding: 5px;
}
.leftnav button.active{width: 90%;background: #005cbb;color: #fff;text-align: left;}