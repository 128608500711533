.report-strip{padding: 10px 15px;font-weight: 600;border: 1.5px solid black;/*width: 800px;*/
    display: inline-block;margin-bottom: 10px;background-color: white;}
.dragging{background-color: silver;}
.with-file{background-color: green;color: white;}
.pdf-container {
    width: 100%;
    height: 610px;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.plainButtons{background-color: transparent !important;box-shadow: none !important;color: #000 !important;
    background-repeat: no-repeat;
    background-position: right center;padding-right: 30px;border: 0px;height: 40px;font-size: 20px;cursor: pointer;}
.reportsbutton{padding-right: 35px !important;margin-right: 30px;background-size: 25px;}
.uploadbutton{margin-right: 20px;background-size: 20px;}
.errorborder{border:1.5px solid red;padding:5px}