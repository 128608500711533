.input-container {
  position: relative;
  width: 300px;
}

.input-wrapper {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;padding-left:12px
}

.input {
  width: 100%;
  border: none;
  outline: none;
  padding: 0px;
  position: relative;
  top:5px;
  font-weight: 400;
  color: #000;
  font-size: 16px;
}

.placeholder {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: 0.3s ease-out;
  font-size: 16px;
  color: #6c7378;
}

.placeholder.shrink {
  top: 25%;
  font-size: 12px;
  color: #6c7378;
}

.detailsform label{font-weight: 600;margin-bottom: 5px;display: inline-block;}
.detailsform .textbox{padding: 5px;width: 320px;font-size: 16px;}
