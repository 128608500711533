.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px 30px;
    border-radius: 5px;
    box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.5);
    min-width: 300px;
    text-align: center;
    max-width: 75%;
  }
  
  .close-button {
    padding: 6px 10px;
    border: 0px solid green;
    background-color: #005cbb;
    color: white;
    border-radius: 5px;
    width: 80px;
    font-size: 16px;
  }
  