.OTPValidation input{width: 40px;height:40px;padding:10px;text-align: center;
    border: 2px solid darkgreen;margin-right: 10px;border-radius: 5px;
    font-size: 30px;
}
.custom-number-input::-webkit-outer-spin-button,
.custom-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input[type='number'] {
  -moz-appearance: textfield;
}